.asa .banner .banner-text {
    color: #FFFFFF;
    font-size: 16pt;
    min-height: 6rem;
    background: linear-gradient(90deg, rgba(92,138,150,1) 0%, rgba(57,121,138,1) 35%, rgba(120,187,204,1) 100%);
    padding: 2rem;
}

.asa .banner .overlap {
    z-index: 10;
}

.asa .banner .moveUp {
    position: relative !important;
    top: -1rem;
}

.asa .banner .marginUp {
    margin-top: -5px;
}
