.bm .expertise .expertise-header {
    color: #142966;
    font-size: larger;
    margin-top: 10px;
    margin-bottom: 10px;
}

.bm .expertise .expertise-item {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align:center;
}

.bm .expertise .expertise-item-header {
    color: #f68e4e;
    font-size: x-large;
    font-weight: bold;
    margin-bottom: 40px;
}
.bm .expertise .expertise-item-image-col{
    background-color:red;
}

.bm .expertise .expertise-item-image {
    margin-bottom: 100px;
    max-width: 280px;
}

    .bm .expertise .expertise-item-image:hover {
        cursor:pointer;
    }

.bm .expertise .expertise-item-info{
    background-color:red;
}


.bm .expertise .jumbotron .background-color {
    background-color: #f1f1f1;
}