ul.no-bullets {
    list-style-type: none; 
    padding: 0; 
    margin: 0; 
}

.block {
    padding-top: 5px;
}

.text-header-box {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.text-header{

}


.text-header-text {
    font-size: xx-large;
    text-align: center;
}

.text-header-underscore{

}

.text-title {
    text-align: center;
}

.text-title-text {
    text-align: center;
    padding-bottom: 5px;
}

.no-gutters {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.img-square {
    width: 400px;
}

.img-border {
    padding: 5px;
    border: 7px solid black;
    background-color: white;
    margin: 5px;
}

.img-border-light {
    padding: 5px;
    border: 7px solid white;
    background-color: black;
    margin: 5px;
}