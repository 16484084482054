.bm .imageInsert.walshBay {
    background: url('../Images/WalshBay.jpg') no-repeat fixed 50% 0;
    background-size: auto !important;
}

.bm .imageInsert.courtyard {
    background: url('../Images/Courtyard.jpg') no-repeat fixed 50% 0;
    background-size: auto !important;
}

.bm .imageInsertInner {
    padding-top: 80px;
    padding-bottom: 800px;
}

.bm #id-walshBay {
    background: url('../Images/WalshBay.jpg');
    background-size: cover;
    padding-bottom: 300px;
}

.bm #id-courtyard {
    background: url('../Images/Courtyard.jpg');
    background-size: cover;
    padding-bottom: 300px;
}
