/*
Blue    #142966
Orange    #f68e4e
Light Orange : #F8CBAD
Grey : #F1F1F1

Primary Orbitron-bold
Secondy Exo 2 Semi bold
*/

.kfc .whiteBkg {
    color: #142966;
    background-color: #fff;
}

.kfc .darkBkg {
    color: #142966;
    background-color: #F1F1F1;
}

.kfc nav.scroll-top {
    background-color: transparent;
}

.kfc nav.scroll-non-top {
    background-color: #fff;
}

.kfc .menu-item {
    color: black;
    font-weight: bold;
    font-size: large;
    text-align:left;
    margin-left:10px;
}
    .kfc .menu-item:hover {
        color: #f68e4e;
    }

.kfc .menu-logo-small {
    max-width: 75px;
}

.kfc .menu-logo-large{
    max-width:100px;
}

.kfc .menu-navbar {
    color: #142966;
    padding: 0;
}

.kfc .menu-brand {
    color: #f68e4e;
    font-size: 26px;
    font-weight: 700;
}

.kfc .navbar-toggler-icon {
    background-image: url(../Images/menuToggler.svg);
}

.kfc .navbar-brand {
    margin-right: 0px;
}

.kfc .navbar-btn-toggler {
    background-color: transparent;
}

.kfc .navbar-toggler-icon {
    background-color: transparent;
}

.kfc .navbar .menu-parent-item {
    text-align: right;
}

.kfc .block .row {
    margin-right: 0;
    margin-left: 0;
}
.kfc .text-header-box {
    margin-top: 65px;
    color: #f68e4e;
}

.kfc .text-header {
    text-align: left;
    font-size: xx-large;
}

.kfc .text-header-text {
    text-align: left;
}

.kfc .text-header-underscore {
    content: "";
    display: none;
    border-top: 6px solid;
    padding-bottom: 12px;
    margin-left: 15px;
}

.kfc .text-title {
    margin-top: 15px;
    margin-bottom: 15px;
}

.kfc .text-title-text {
    font-size: large;
    text-align: left;
}

.kfc .text-title-text-highlight {
    font-size: large;
    color: #f68e4e;
}

.kfc {
    font-family: exo 2
}

    .kfc ul {
        list-style: none; /* Remove default bullets */
    }


    .kfc .sponsors ul {
        list-style: disc; 
    }

    .kfc .sponsors li {
        margin-bottom: 10px;
    }

    .kfc .rules ul {
        list-style: disc;
    }

    .kfc .rules li {
        margin-bottom: 10px;
    }

    .kfc .register ul {
        list-style: disc;
    }

    .kfc .register li {
        margin-bottom: 10px;
    }


    .kfc .clist ul li::before {
        content: "\2022"; 
        color: #f68e4e; 
        font-weight:900; 
        display: inline-block; 
        width: 1em;
        margin-left: -1em; 
    }

    .kfc .content-highlight {
        font-weight: bold;
        color: #142966;
    }

    .kfc .footer {
        color: #FFF;
        background-color: #142966;
        padding-top: 5px;
        padding-bottom: 5px;
    }

        .kfc .footer .footer-message {
            text-align: center;
            margin-top: 5px;
            margin-bottom: 5px;
        }

.kfc .infoblock .definition p {
    color: #142966;
    font-size: larger;
    margin-top: 10px;
    margin-bottom: 30px;
}

.kfc .infoblock .parragraphs p {
    color: #142966;
    font-size: larger;
    margin-top: 10px;
    margin-bottom: 30px;
}

.kfc .infoblock .parragraphs .highlight {
    color: #f68e4e;
    font-weight: bold;
}

    .kfc .sponsor-item {
        max-width:250px;
    }

    /*Inserts form KFC*/
    .kfc .imageInsertInner {
        padding-top: 80px;
        padding-bottom: 800px;
    }

    /*IMAGE INSERT - 1*/
    .kfc .imageInsert.kfc-school {
        background: url('../Images/ImageInsert_4.JPG') no-repeat fixed 0;
        background-size: auto !important;
    }

    .kfc #id-kfc-school {
        background: url('../Images/ImageInsert_4.JPG');
        background-size: cover;
        padding-bottom: 300px;
    }

    /*IMAGE INSERT - 1*/
    .kfc .imageInsert.kfc-kill {
        background: url('../Images/kingfish_background.jpg') no-repeat fixed 50% 0;
        background-size: auto !important;
    }

    .kfc #id-kfc-kill {
        background: url('../Images/kingfish_background.jpg');
        background-size: cover;
        padding-bottom: 300px;
    }

    /*IMAGE INSERT - 1*/
    .kfc .imageInsert.kfc-collage {
        background: url('../Images/Collage.jpg') no-repeat fixed 30% 0;
        background-size: auto !important;
    }

    .kfc #id-kfc-collage {
        background: url('../Images/Collage.jpg');
        background-size: cover;
        padding-bottom: 300px;
    }


    .kfc #gridDivers {
        width: 100%;
        height: 1000px;
    }

.dive-events {
    line-height: normal;
    font-size: x-small;
    margin-top:5px;
}


.kfc .signonoff{
    padding-bottom:20px;
}