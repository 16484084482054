.bm .projects {
    background-color: transparent;
    color: #142966;
    font-size: larger;
}

.bm .projects .project {
}
    .bm .projects .project {
        cursor: pointer;
    }

.bm .projects .project-img {
    position: static;
    z-index: 1;
}
    .bm .projects .project-img:hover {
        z-index: -10 !important;
        opacity: 0.1;
        transition: opacity 0.5s;
    }

.bm .projects .project-info {
    z-index: 2;
    color: black;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px;
    font-size: 20px;
    line-height: 28px;
}

    .bm .projects .projectPopUp a {
        display: block;
        height: 0;
        padding-bottom: 80%;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;
    }
        .bm .projects .projectPopUp a:hover {
            cursor: pointer;
        }
    .bm .projects .projectPopUp .projectPopUp-img img {
        display: block;
        width: 100%;
    }
    .bm .projects .projectPopUp .projectPopUp-img-effect {
        opacity: 0;
    }
    .bm .projects .projectPopUp:hover .projectPopUp-img-effect {
        display: block;
        position: absolute;
        top: -100px;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(254,248,244,0.99);
        opacity: 0.9;
    }
    .bm .projects .projectPopUp .projectPopUp-img-effect-desc {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 15px;
        color: #142966;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
    }



.bm .projects .project-cat{
    font-size:larger;
}

.bm .projects .project-desc {
    font-size: large;
    font-weight:100;
}

.modal-project-img {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}



.modal-project.modal-dialog {
    /*max-width: 780px !important;
    width: calc(100% - 100px);*/
}

.modal-project .modal-content {
    border: 2px solid #fff;
}
    .modal-project .modal-content .modal-content-xs {
        width: 85%;
    }

.modal-project .modal-body {
    padding: 1px;
}

.modal-project .modal-project-cat {
    font-size: x-large;
    font-weight: bold;
    color: #142966;
}

.modal-close-button:hover {
    cursor: pointer;
}

.modal-project .modal-project-info {
    font-size: large;
    font-weight: bold;
    color: #142966;

    margin-top: 10px;
    margin-bottom: 10px;
}
.modal-project .modal-project-info-sep {
    color: #F8CBAD;
}

.modal-project .modal-project-desc {
    font-size: large;
    color: #142966;
}
