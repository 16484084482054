.bm .about {
    background-color: #F1F1F1;
}

.bm .aboutInner {
    background-color: transparent;
}

.bm .about .definition p {
    color: #142966;
    font-size: larger;
    margin-top: 10px;
    margin-bottom: 30px;
}

.bm .about .parragraphs p {
    color: #142966;
    font-size: larger;
    margin-top: 10px;
    margin-bottom: 30px;
}

.bm .parragraphs .highlight {
    color: #f68e4e;
    font-weight: bold;
}