.meta-text h3 {
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 400;
}

.meta-text {
    margin-left: 80px;
}

.featured-item .icon-style {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 2px solid #33033D;
    text-align: center;
    padding: 5px 0;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.featured-item .icon-style:hover {
        border-radius: 0;
    }

.featured-item .icon {
    font-size: 30px;
    margin-top: 0px;
    width: 60px;
    height: 60px;
    float: left;
}
