.asa .top {
}
    .asa .top .abc::after {
        content: "Australian Spearfishing Academy\00000aYour first step to learn freediving and spearfishing";
        position: absolute;
        top: 80px;
        white-space: pre;
        right: 50px;
        font-weight: bold;
        text-align: right;
        font-size: 20px;
    }

