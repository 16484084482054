/*.kfc .sponsors {
    background-color: #F1F1F1;
}*/

    .kfc .sponsors p {
        margin-top: 20px;
        margin-bottom: 20px;
        color: #142966;
        font-size: larger;
    }

    .kfc .sponsors .highlight {
        color: #f68e4e;
        font-weight: bold;
    }


.kfc .sponsors .sponsor-item{
    max-width:150px;
}

.kfc .sponsor{
    text-align:center;
    margin-top:20px;
}


.kfc .main-price
{
    margin-bottom:20px;
}