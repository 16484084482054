
.kfc .top .container {
    max-width: 100% !important;
    padding-top:75px;
}

.kfc .top .top-dates, .top-register {
    font-size: larger;
    color:darkorange;
    font-weight: bold;
}
/*Go Live Comments */

/*Go Live Comments
.kfc .top-inner-lg {
    background: url('../Images/ASKFC-23-DarkCircleTransparent.png') no-repeat fixed 50% 0;
    background-size: auto !important;
}

.kfc .top-inner-xs {
    background: url('../Images/ASKFC-23-DarkCircleTransparent.png') -10%;
    background-size: cover;
}

.kfc .top-inner-lg {
    padding-top: 100px;
    padding-bottom: 600px;
}

.kfc .top .block .row {
    margin-right: -15px;
    margin-left: -15px;
}

.kfc .top-inner-lg {
    padding-top: 1000px;
    padding-bottom: 700px;
}

.kfc .top-inner-xs {
    padding-top: 50px;
    padding-bottom: 200px;
    margin-right: -15px;
    margin-left: -15px;
}
*/
.kfc .top.top-inner-bottom {
    padding-top: 80px;
    padding-bottom: 1000px;
}

.kfc .top-inner a{  
    margin-top:150px;
}

.kfc .top-inner a {
    font-size:larger;
    font-weight:bold;
}

.kfc .top-message {
    color: #fff;
    font-size: xx-large;
    text-align: justify;
}

.kfc .top-war-cry {
    color: black;
    vertical-align: bottom;
    font-weight: bold;
    text-align: right;
}

.kfc .top-war-cry-lg {
    font-size: xx-large;
}

.kfc .top-war-cry-xs {
    font-size: large;
}

.kfc .top-war-cry-sep {
    color: #142966;
}