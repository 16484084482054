.bm .meetTheDirector {
    background-color: #F1F1F1;
}

.bm .meetTheDirectorInner  {
    background-color: transparent;
}

.bm .meetTheDirector  p {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #142966;
    font-size: larger;
}

.bm .meetTheDirector .highlight {
    color: #f68e4e;
    font-weight: bold;
}

.bm .meetTheDirector .meetTheDirector-image-col .meetTheDirector-image {
    /*max-width: 400px;
    margin-top: -50px;*/
}

.bm .meetTheDirector .meetTheDirector-image-col {
}

