.asa .partners{
    background: #222;
    color: #aaa;
    padding-top: 10px;
}

    .asa .partners a {
        color: #aaa;
    }

        .asa .partners a:hover {
            color: #fff;
        }

    .asa .partners h3 {
        color: #3a8fa6;
        letter-spacing: 1px;
        margin: 30px 0 20px;
    }

    .asa .partners .three-column {
        overflow: hidden;
    }

        .asa .partners .three-column li {
            width: 33.3333%;
            float: left;
            padding: 5px 0;
        }

    .asa .partners .socila-list {
        overflow: hidden;
        margin: 20px 0 10px;
    }

        .asa .partners .socila-list li {
            float: left;
            margin-right: 3px;
            opacity: 0.7;
            overflow: hidden;
            border-radius: 50%;
            transition: all 0.3s ease-in-out;
        }

            .asa .partners .socila-list li:hover {
                opacity: 1;
            }

.asa .partners .img-thumbnail {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid #444;
    margin-bottom: 5px;
}

    .asa .partners .copyright {
        padding: 15px 0;
        font-size: 15px;
    }

        .asa .partners .copyright span {
            color: #0894d1;
        }
