.bm .locationsAndContact {
    color: #142966;
    font-size: larger;
}

    .bm .locationsAndContact .contact-image {
        margin-top: 20px;
        margin-left: -15px;
    }

    .bm .locationsAndContact .contact-item {
        margin-top:15px;
    }

    .bm .locationsAndContact .contact-item-header {
        color: #f68e4e;
        font-weight: bold;
        margin-bottom: 0px;
        font-size:large;
    }

    .bm .locationsAndContact .contact-item-text {
    }

    .bm .locationsAndContact .contact-item-text-anchor {
        color: #142966;
    }


.bm .locationsAndContact .contact-item-image {
    max-width: 120px;
    margin-left: -20px;
}

    .bm .locationsAndContact .contact-header .highlight {
        color: #f68e4e;
    }

    .bm .locationsAndContact .contact-item-image:hover {
        -webkit-transform: rotate(15deg);
        -moz-transform: rotate(15deg);
        transform: rotate(15deg);
        -webkit-transform: scale(1.4);
        -moz-transform: scale(1.4);
        transform: scale(1.4);
        z-index: 10;
        -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
        -moz-box-shadow: 0 10px 20px rgba(0,0,0,.7);
        box-shadow: 0 10px 20px rgba(0,0,0,.7);
    }



