a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navigation .navbar-default .navbar-nav > li > a:hover, .navigation .navbar-default .navbar-nav > .active > a, .navigation .navbar-default .navbar-nav > .active > a:hover, .navigation .navbar-default .navbar-nav > .active > a:focus {
    background: none;
    color: #BF0BE6;
}

.sticky-top {
    position: fixed !important;
    width: 100%;
    top: 0;
}
