.price .price-table {
    padding: 0 30px;
    position: relative;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    background-color: #fff;
    margin-top: 30px;
    color: #FFFFFF;
    background-image: url('/Content/Images/pattern-1.png');
    background-repeat: repeat;
    background-color: #660066;
}

.price .header .underline {
    margin-bottom: 10px;
}

.price .price-list {
    text-align: center;
}

    .price .price-list i {
        margin-right: -5px;
    }

    .price .price-list .price-header {
        margin-top: 100px;
    }

    .price .price-list h2 {
        display: inline-block;
    }

    .price .price-list p {
        margin-bottom: 30px;
        text-align: left;
    }

    .price .price-list ul {
        padding-left: 10px;
        padding-bottom: 50px;
        text-align: left;
    }

        .price .price-list ul li {
            list-style: none;
            margin-bottom: 15px;
        }

            .price .price-list ul li i {
                width: 24px;
                height: 24px;
                border-radius: 100%;
                text-align: center;
                padding: 4px 0;
                font-size: 14px;
            }

                .price .price-list ul li i.fa-check {
                    margin-right: 20px;
                    border: 1px solid #33033D;
                    color: #33033D;
                }

                .price .price-list ul li i.fa-times {
                    margin-right: 20px;
                    border: 1px solid #e74c3c;
                    padding-right: 1px;
                    color: #e74c3c;
                }

    .price .price-list .rate {
        background: #33033D;
        color: #fff;
        font-size: 35px;
        text-align: center;
        padding: 20px 0;
        border-radius: 100%;
        width: 180px;
        height: 180px;
        position: absolute;
        top: -75px;
        left: 50%;
        margin-left: -75px;
        font-weight: 700;
    }

    .price .price-list .buy-now {
        border: 1px solid transparent;
        background: #33033D;
        padding: 10px 30px;
        border-radius: 0;
        font-size: 20px;
        margin-bottom: 30px;
        color: #fff;
        position: absolute;
        left: 50%;
        bottom: -55px;
        margin-left: -75px;
    }

.price .featured-price .rate,
.price .featured-price .buy-now {
    background: #e74c3c;
}
