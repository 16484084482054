.gallery-image {
}

.gallery-image-container {
}

.gallery-image-rotated {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transform: rotate(90deg);
}

.polaroid-images a {
    display: inline;
    float: left;
    margin: 0 15px 40px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 4px 6px rgba(0,0,0,.3);
    box-shadow: 0 4px 6px rgba(0,0,0,.3);
    -webkit-transition: all .15s linear;
    -moz-transition: all .15s linear;
    transition: all .15s linear;
    z-index: 0;
    position: relative;
}

.polaroid-images a:after {
    color: #333;
    font-size: 20px;
    content: attr(title);
    position: relative;
    top: 15px;
}

.polaroid-images img {
    display: block;
    width: inherit;
}

.polaroid-images a:nth-child(2n) {
    -webkit-transform: rotate(4deg);
    -moz-transform: rotate(4deg);
    transform: rotate(4deg);
}

.polaroid-images a:nth-child(3n) {
    -webkit-transform: rotate(-24deg);
    -moz-transform: rotate(-24deg);
    transform: rotate(-24deg);
}

.polaroid-images a:nth-child(4n) {
    -webkit-transform: rotate(14deg);
    -moz-transform: rotate(14deg);
    transform: rotate(14deg);
}

.polaroid-images a:nth-child(5n) {
    -webkit-transform: rotate(-18deg);
    -moz-transform: rotate(-18deg);
    transform: rotate(-18deg);
}

.polaroid-images-small a:hover {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    transform: rotate(15deg);
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    transform: scale(1.4);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,.7);
    box-shadow: 0 10px 20px rgba(0,0,0,.7);
}

.polaroid-images-large a:hover {
    -webkit-transform: rotate(15deg);
    -moz-transform: rotate(15deg);
    transform: rotate(15deg);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    -moz-box-shadow: 0 10px 20px rgba(0,0,0,.7);
    box-shadow: 0 10px 20px rgba(0,0,0,.7);
}
